<template>
  <div class="card">
    <div style="margin: auto">
      <p style="text-align: center">{{ $t("etablissement.cache") }}</p>

      <div class="upload-example" style="width: 600px" v-if="!stampURL">
        <cropper
          v-show="showCropper"
          ref="cropper"
          class="upload-example-cropper"
          :stencil-size="{
            width: 500,
            height: 300,
          }"
          :stencil-props="{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1.66,
          }"
          image-restriction="stencil"
          :src="image.src"
        />
        <a-progress
          :stroke-color="{
            from: '#108ee9',
            to: '#87d068',
          }"
          v-show="uploadPercentage > 0"
          :percent="uploadPercentage"
          status="active"
        />
        <!-- <div class="button-wrapper">
          <button class="button" @click="$refs.file.click()">
            <input
              type="file"
              ref="file"
              @change="loadImage($event)"
              accept="image/*"
            />
            Load image
          </button>
        </div> -->
        <a-upload-dragger
          v-show="!showCropper"
          :multiple="false"
          :file-list="fileList"
          :customRequest="addTofileList"
          :before-upload="beforeUploadFiles"
        >
          <div>
            <p class="ant-upload-drag-icon">
              <a-icon :type="'file'" />
            </p>
            <div class="ant-upload-text">
              {{ $t("etablissement.clickOrDragToUpload") }}
            </div>
          </div>
        </a-upload-dragger>
      </div>
      <div v-else>
        <img :src="stampURL" width="300" />
      </div>
      <div style="text-align: center" class="m-3">
        <a-button type="danger" v-if="stampURL">
          <a-popconfirm
            :title="$t('requis.supp')"
            @confirm="
              () => {
                supp();
              }
            "
          >
            <a>{{ $t("action.supprimer") }}</a>
          </a-popconfirm></a-button
        >
        <div v-else>
          <a-button
            class="ml-1 mr-1"
            @click="
              () => {
                cancelUpload();
              }
            "
            v-if="showCropper"
          >
            Annuler
          </a-button>
          <a-button type="primary" @click="crop" :loading="loadingAdd">
            Ajouter
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

export default {
  name: "parametrage-etablissement-autre",
  computed: mapState(["settings", "user"]),
  components: {
    Cropper,
    Preview,
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Autre",
    });

    apiClient
      .get("/buildings/" + this.settings.activeBuildingId + "/stamp")
      .then((res) => {
        if (res.data.imageURL) {
          this.stampURL = this.settings.base_url + "/" + res.data.imageURL;

          this.image.src = this.settings.base_url + "/" + res.data.imageURL;
        }
      })
      .catch((e) => {
        console.error(e);
        this.$message.error("Impossible d'obtenir le caché");
      });
  },
  data() {
    return {
      loadingAdd: false,
      showCropper: false,
      fileList: [],
      uploadPercentage: 0,
      stampURL: "",
      img: null,
      result: {
        coordinates: null,
        image: null,
      },
      image: {
        src: null,
        type: null,
      },
    };
  },
  methods: {
    change({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      };
    },

    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
        // You can use axios, superagent and other libraries instead here
        const formData = new FormData();
        formData.append("photo", blob, "stamp.png");
        this.loadingAdd = true;
        apiClient
          .patch(
            "/buildings/" + this.settings.activeBuildingId + "/stamp",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then((res) => {
            /*update state*/
            let buildingToUpdate = this.user.building.find(
              (b) => b._id == this.settings.activeBuildingId
            );
            buildingToUpdate.stamp = res.data.imageURL;
            const newUserBuildingsList = this.user.building.map((b) =>
              b._id == this.settings.activeBuildingId ? buildingToUpdate : b
            );
            console.log(newUserBuildingsList);
            this.$store.commit("user/SET_STATE", {
              building: newUserBuildingsList,
            });

            this.stampURL = this.settings.base_url + "/" + res.data.imageURL;
            this.$message.success(this.$t("success.cacheAjout"));
            this.uploadPercentage = 0;
          })
          .catch((e) => {
            console.error(e);
            this.$message.error(this.$t("error.impoTelecharger"));
          })
          .finally(() => {
            this.loadingAdd = false;
          });
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    destroyed() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
        this.image.src = null;
      }
    },
    beforeUploadFiles(file, _) {
      this.img = file;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(
          "Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!"
        );
      }
      const isSIZE_50MB = file.size / 1024 / 1024 < 50;
      if (!isSIZE_50MB) {
        this.$message.error("L'image doit être inférieure à 50 Mo!");
      }

      if (isJpgOrPng && isSIZE_50MB) {
        this.loadImage({ target: { files: [file] } });
        this.showCropper = true;
      }
      return isJpgOrPng && isSIZE_50MB;
    },
    supp() {
      apiClient
        .delete("/buildings/" + this.settings.activeBuildingId + "/stamp")
        .then((res) => {
          /*update state*/
          let buildingToUpdate = this.user.building.find(
            (b) => b._id == this.settings.activeBuildingId
          );
          buildingToUpdate.stamp = null;
          const newUserBuildingsList = this.user.building.map((b) =>
            b._id == this.settings.activeBuildingId ? buildingToUpdate : b
          );
          this.$store.commit("user/SET_STATE", {
            building: newUserBuildingsList,
          });
          this.stampURL = "";
          this.destroyed();

          this.$message.success(this.$t("success.cacheSupp"));
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.showCropper = false;
        });
    },
    addTofileList(file) {
      this.fileList = [file];
    },

    cancelUpload() {
      this.showCropper = false;
      this.fileList = [];
      this.destroyed();
    },
  },
};
</script>
<style scoped>
[data-kit-theme="default"] .ant-upload .ant-upload-select-picture-card {
  width: 200px !important;
  height: 200px !important;
  border: 3px dashed #e4e9f0;
  border-radius: 7px;
  cursor: pointer;
}
</style>
